export function getFormStateColor(state) {
    return FORM_STATE_COLORS[state];
}
export function getFormStateIcon(state) {
    return FORM_STATE_ICONS[state];
}
export function getFormStateTooltip(state) {
    return FORM_STATE_TOOLTIPS[state];
}
export function getValidationStateColor(state) {
    return VALIDATION_STATE_COLORS[state];
}
export function getValidationStateIcon(state) {
    return VALIDATION_STATE_ICONS[state];
}
export function getValidationStateTooltip(state) {
    return VALIDATION_STATE_TOOLTIPS[state];
}
export const VALIDATION_STATES = ["error", "required", "success", "none"];
export const FORM_STATES = [...VALIDATION_STATES, "kisynced", "template", "ocr"];
const VALIDATION_STATE_ICONS = {
    error: "state-error",
    required: "state-required",
    success: "state-success",
    none: "state-error", // intended because invisible
};
const VALIDATION_STATE_COLORS = {
    error: "red-100",
    required: "gold",
    success: "green-100",
    none: "transparent",
};
const VALIDATION_STATE_TOOLTIPS = {
    error: "fehlende/fehlerhafte Angaben",
    required: "markiert Pflichtfeld",
    success: "alle Pflichtfelder befüllt",
    none: "",
};
const FORM_STATE_ICONS = Object.assign(Object.assign({}, VALIDATION_STATE_ICONS), { kisynced: "ki-synced", template: "chevron-rechts-kreis-fill", ocr: "scan" });
const FORM_STATE_COLORS = Object.assign(Object.assign({}, VALIDATION_STATE_COLORS), { kisynced: "gray-50", template: "gold", ocr: "gray-50" });
const FORM_STATE_TOOLTIPS = Object.assign(Object.assign({}, VALIDATION_STATE_TOOLTIPS), { kisynced: "synchronisiert systemübergreifend", template: "aus einer Vorlage befüllt", ocr: "aus automatischer Texterkennung befüllt" });
